import { type LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk';

import { setFeatureFlags } from '../faro';

const EVENT_FLOW_PAGE_VARIANTS = ['prerelease', 'release', 'disabled', 'canary', undefined] as const;

type Flags = {
  cmdk: boolean;
  eventFlowPage: (typeof EVENT_FLOW_PAGE_VARIANTS)[number];
  pieChart: boolean;
  exemplars: boolean;
  slashQuery: boolean;
};

function defaultFlags(): Flags {
  return {
    cmdk: false,
    eventFlowPage: EVENT_FLOW_PAGE_VARIANTS.find((variant) => variant === process.env.EVENT_FLOW_PAGE) || 'disabled',
    pieChart: false,
    exemplars: false,
    slashQuery: false,
  };
}

export const flags: Flags = defaultFlags();

type CombinedFeatureFlags = {
  ldFlags: LDFlagSet;
  mockedFlags: Record<string | symbol, any>;
};

/**
 * 🐉🐉🐉
 * The return of `useFlags` is a proxy that phones home to LaunchDarky every time one
 * of its properties is accessed. Previously, `useFeatureFlags` was spreading it,
 * which caused every property to get accessed every time `useFeatureFlags` was called.
 *
 * We only want it to phone home when a flag is actually checked for, so a new proxy is
 * created that allows us to get this behavior.
 */
const combinedHandler: ProxyHandler<CombinedFeatureFlags> = {
  get: (target, property: string) => {
    if (property in target.mockedFlags) {
      return target.mockedFlags[property];
    }
    if (property in target.ldFlags) {
      return target.ldFlags[property]; // this triggers the proxy get trap
    }

    return undefined;
  },
  ownKeys: (target) => {
    return Reflect.ownKeys(target.ldFlags).concat(Reflect.ownKeys(target.mockedFlags));
  },
  has: (target, property) => {
    return property in target.ldFlags || property in target.mockedFlags;
  },
};

const wrappedHandler: ProxyHandler<CombinedFeatureFlags> = {
  get: (target, property: string) => {
    const value = Reflect.get(target, property);

    setFeatureFlags({ [property]: value });

    return value;
  },
  ownKeys: (target) => {
    return Reflect.ownKeys(target);
  },
  has: (target, property) => {
    return Reflect.has(target, property);
  },
};

export const useFeatureFlags = () => {
  const ldFlags = useFlags();
  const mockedFlags = window.__mocks?.featureFlags ?? {};

  const combinedTarget: CombinedFeatureFlags = { ldFlags: ldFlags, mockedFlags: mockedFlags };
  const combinedProxy = new Proxy(new Proxy(combinedTarget, combinedHandler), wrappedHandler) as unknown as Flags;

  return combinedProxy;
};
