import {
  type Faro,
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { Route } from 'react-router-dom';

import { axiom } from '../axiom';

import { browserHistory } from './browserHistory';
import { getEnv } from './env';
import { cloneUnknown } from './objects';

let faro: Faro | null = null;

export const getFaro = () => faro;

type CustomEvents =
  | 'annotation_selected'
  | 'beta_builder_submission'
  | 'chart_input_validation_not_satisfied'
  | 'legacy_builder_submission'
  | 'apl_error_after_wrangle'
  | 'note_element_created_or_updated';

type AplWrangleKind = 'zoom' | 'showEvents';

type OnlyStringValues<T extends Record<string, string>> = T;
/**
 * 🚨🚨🚨
 *
 * Hey you! Yes you! If you're reading this, you're probably about to add
 * a new attribute to ingest with Faro.
 *
 * Before you do so, please ask yourself:
 * - Does this risk leaking PII or any other type of sensitive data that
 *   our users would not want us to have?
 * - Will this data be useful for debugging or investigating issues?
 *
 * If you're not sure, please ask!
 */
type FaroEventAttributes = OnlyStringValues<{
  chartInput_apl?: string;
  chartInput_chartType?: string;
  chartInput_errors?: string;
  apl_wrangle_kind?: AplWrangleKind;
  trace_id?: string;
  noteElement_interactionType?: 'create' | 'update';
  noteElement_elements?: string;
}>;

export const pushEvent = (eventName: CustomEvents, attributes?: FaroEventAttributes) => {
  faro?.api.pushEvent(eventName, attributes);
};

export const useFaro = () => {
  return {
    pushEvent: pushEvent,
  };
};

export function initFaro() {
  if (faro) {
    return;
  }

  if (axiom.enableJSMonitoring) {
    faro = initializeFaro({
      url: '/exapi/instrument',
      app: {
        name: 'app',
        version: process.env.VERSION,
        environment: getEnv(),
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [LogLevel.INFO, LogLevel.LOG],
        }),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              history: browserHistory as any,
              Route: Route,
            },
          },
        }),
      ],
      batching: {
        enabled: true,
        sendTimeout: 5000, // default is 250
        itemLimit: 50,
      },
    });

    window.document.addEventListener(
      'visibilitychange',
      () => {
        if (window.document.visibilityState !== 'visible') {
          faro?.pause();
        } else {
          faro?.unpause();
        }
      },
      false
    );
  }
}

export const setFeatureFlags = (featureFlags: Record<string, string | boolean | undefined>) => {
  const f = getFaro();

  const attributes = cloneUnknown(f?.metas.value.session?.attributes || {});

  // iterate over featureFlags and set each one in the attributes object
  Object.entries(featureFlags).forEach(([key, value]) => {
    if (value !== undefined) {
      attributes[key] = value.toString();
    } else {
      delete attributes[key];
    }
  });

  f?.api.setSession({
    ...f.metas.value.session,
    attributes: attributes,
  });
};

export const setFeatureFlag = (featureFlag: string, value: string | boolean | undefined) => {
  setFeatureFlags({ [featureFlag]: value });
};
